#projectTypeContainer {
  display: flex;
  justify-content: space-between;
}

#projectTypeContainer .form-group {
  width: 100%;
}

#fenceTypeSelect.fenceTypeSelectHasProjects {
  width: calc(100% - 40px);
}

#removeProjectBtn {
  margin: 2rem 0 0 2rem;
  width: 30px;
  height: 30px;
  padding: 0;
}

#removeProjectBtn svg {
  color: #fff;
}
