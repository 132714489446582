.projectVisualisationGateTilting {
    display: flex;
}

.projectVisualisationGateTilting__spanMiddle,
.projectVisualisationGateTilting__spanMiddleTop,
.projectVisualisationGateTilting__spanMiddleBottom,
.projectVisualisationGateTilting__spanLeft,
.projectVisualisationGateTilting__spanRight,
.projectVisualisationGateTilting__spanTop,
.projectVisualisationGateTilting__spanBottom,
.projectVisualisationGateTilting__spanLeftTop,
.projectVisualisationGateTilting__spanLeftBottom,
.projectVisualisationGateTilting__spanRightTop,
.projectVisualisationGateTilting__spanRightBottom,
.projectVisualisationBody__gateTiltingTop,
.projectVisualisationBody__gateTiltingBottom,
.projectVisualisationBody__gateTilting {
    position: absolute;
    background-size: contain;
}

.projectVisualisationGateTilting__spanMiddle {
    width: 190px;
    margin-left: -95px;
    height: calc(100% - 160px);
    left: 50%;
    top: 90px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/middle.png");
}

.projectVisualisationGateTilting__spanMiddleTop,
.projectVisualisationGateTilting__spanMiddleBottom {
    left: 50%;
    width: 190px;
    margin-left: -95px;
}

.projectVisualisationGateTilting__spanMiddleTop {
    height: 90px;
    top: 2px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/top-middle.png");
}

.projectVisualisationGateTilting__spanMiddleBottom {
    height: 89px;
    bottom: 10px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/bottom-middle.png");
}

.projectVisualisationGateTilting__spanLeft,
.projectVisualisationGateTilting__spanRight {
    height: calc(100% - 166px);
    width: 231px;
    margin-top: 79px;
}

.projectVisualisationGateTilting__spanTop {
    height: 80px;
    top: -79px;
    width: 100%;
    left: 0;
}

.projectVisualisationGateTilting__spanBottom {
    height: 196px;
    bottom: -196px;
    width: 100%;
    right: 0;
    background-repeat: no-repeat;
}

.projectVisualisationGateTilting__spanLeft {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/left.png");
}

.projectVisualisationGateTilting__spanLeftTop,
.projectVisualisationGateTilting__spanLeftBottom {
    width: 231px;
    left: 0;
    background-repeat: no-repeat;
}

.projectVisualisationGateTilting__spanLeftTop {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/top-left.png");
    top: 0;
    height: 80px;
}

.projectVisualisationGateTilting__spanLeftBottom,
.projectVisualisationGateTilting__spanRightBottom {
    height: 160px;
    bottom: -71px;
}

.projectVisualisationGateTilting__spanLeftBottom {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/bottom-left.png");
}

.projectVisualisationGateTilting__spanRightBottom {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/bottom-right.png");
}

.projectVisualisationGateTilting__spanRightTop,
.projectVisualisationGateTilting__spanRightBottom {
    width: 231px;
    right: 0;
    background-repeat: no-repeat;
}

.projectVisualisationGateTilting__spanRight {
    right: 0;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/right.png");
}

.projectVisualisationGateTilting__spanRightTop {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/top-right.png");
    top: 0;
    height: 80px;
}

.projectVisualisationBody__gateTilting {
    height: calc(100% - 160px);
    margin-top: 90px;
    width: calc(100% - 462px);
    left: 231px;
}

.projectVisualisationBody__gateTiltingTop {
    height: 80px;
    left: 231px;
    width: calc(100% - 462px);
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateTilting/top.png");
}

.projectVisualisationBody__gateTiltingBottom {
    bottom: -70px;
    height: 160px;
    left: 231px;
    width: calc(100% - 462px);
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/bottom.png");
}

/*
* data-scale="2"
*/

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanMiddle {
    width: 80px;
    margin-left: -47px;
    height: calc(100% - 80px);
    top: 45px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanMiddleTop,
.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanMiddleBottom {
    width: 80px;
    margin-left: -47px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanMiddleTop {
    height: 45px;
    top: 2px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanMiddleBottom {
    height: 38px;
    bottom: 4px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanLeft,
.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanRight {
    height: calc(100% - 82px);
    width: 115px;
    margin-top: 39px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanTop {
    height: 40px;
    top: -39px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanBottom {
    height: 196px;
    bottom: -196px;
    width: 100%;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanLeftTop,
.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanLeftBottom {
    width: 115px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanLeftTop,
.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanRightTop {
    height: 40px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanLeftBottom,
.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanRightBottom {
    bottom: -34px;
    height: 77px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanRightTop,
.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationGateTilting__spanRightBottom {
    width: 115px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationBody__gateTilting {
    height: calc(100% - 40px);
    margin-top: 40px;
    width: calc(100% - 230px);
    left: 115px;
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationBody__gateTiltingTop {
    height: 40px;
    left: 115px;
    width: calc(100% - 230px);
}

.projectVisualisationGateTilting[data-scale="2"] .projectVisualisationBody__gateTiltingBottom {
    bottom: -36px;
    height: 80px;
    left: 115px;
    width: calc(100% - 230px);
}

/*
* data-scale="3"
*/

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanMiddle {
    width: 53px;
    margin-left: -31px;
    height: calc(100% - 53px);
    top: 30px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanMiddleTop,
.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanMiddleBottom {
    width: 53px;
    margin-left: -31px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanMiddleTop {
    height: 30px;
    top: 2px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanMiddleBottom {
    height: 26px;
    bottom: 4px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanLeft,
.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanRight {
    height: calc(100% - 54px);
    width: 76px;
    margin-top: 26px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanTop {
    height: 40px;
    top: -39px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanBottom {
    height: 130px;
    bottom: -130px;
    width: 100%;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanLeftTop,
.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanLeftBottom {
    width: 76px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanRightTop,
.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanLeftTop {
    height: 27px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanLeftBottom,
.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanRightBottom {
    bottom: -22px;
    height: 52px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanRightTop,
.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationGateTilting__spanRightBottom {
    width: 76px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationBody__gateTilting {
    height: calc(100% - 26px);
    margin-top: 30px;
    width: calc(100% - 150px);
    left: 76px;
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationBody__gateTiltingTop {
    height: 26px;
    left: 76px;
    width: calc(100% - 150px);
}

.projectVisualisationGateTilting[data-scale="3"] .projectVisualisationBody__gateTiltingBottom {
    bottom: -22px;
    height: 53px;
    left: 76px;
    width: calc(100% - 151px);
}

/*
* data-scale="4"
*/

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanMiddle {
    width: 40px;
    margin-left: -23px;
    height: calc(100% - 40px);
    top: 22px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanMiddleTop,
.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanMiddleBottom {
    width: 40px;
    margin-left: -23px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanMiddleTop {
    height: 22px;
    top: 1px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanMiddleBottom {
    height: 19px;
    bottom: 2px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanLeft,
.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanRight {
    height: calc(100% - 38px);
    width: 57px;
    margin-top: 19px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanTop {
    height: 20px;
    top: -24px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanBottom {
    height: 98px;
    bottom: -98px;
    width: 100%;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanLeftTop,
.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanLeftBottom {
    width: 57px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanLeftTop,
.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanRightTop {
    height: 20px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanLeftBottom,
.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanRightBottom {
    bottom: -20px;
    height: 39px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanRightTop,
.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationGateTilting__spanRightBottom {
    width: 57px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationBody__gateTilting {
    height: calc(100% - 20px);
    margin-top: 20px;
    width: calc(100% - 114px);
    left: 57px;
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationBody__gateTiltingTop {
    height: 20px;
    left: 57px;
    width: calc(100% - 114px);
}

.projectVisualisationGateTilting[data-scale="4"] .projectVisualisationBody__gateTiltingBottom {
    bottom: -20px;
    height: 40px;
    left: 57px;
    width: calc(100% - 114px);
}
