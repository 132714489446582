.projectVisualisationGateSelfSupporting {
    display: flex;
    position: relative;
}

.projectVisualisationGateSelfSupporting .projectVisualisationGateSelfSupporting__spanTop,
.projectVisualisationGateSelfSupporting .projectVisualisationGateSelfSupporting__spanBottom,
.projectVisualisationGateSelfSupporting .projectVisualisationBody__gateSelfSupportingTop,
.projectVisualisationGateSelfSupporting .projectVisualisationBody__gateSelfSupportingBottom {
    position: absolute;
    background-size: contain;
}

.projectVisualisationGateSelfSupporting .projectVisualisationGateSelfSupporting__span {
    height: calc(100% - 214px);
    position: relative;
    background-size: contain;
}

.projectVisualisationGateSelfSupporting .projectVisualisationGateSelfSupporting__span {
    width: 214px;
    margin-top: 80px;
    position: absolute;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateSelfSupporting/left.png");
}

.projectVisualisationGateSelfSupporting .projectVisualisationGateSelfSupporting__spanTop {
    height: 120px;
    top: -37px;
    width: 212px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateSelfSupporting/top-left.png");
    left: 0;
    background-repeat: no-repeat;
}

.projectVisualisationGateSelfSupporting .projectVisualisationGateSelfSupporting__spanBottom {
    height: 262px;
    bottom: -102px;
    width: 308px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateSelfSupporting/bottom-left.png");
    left: 0;
    background-repeat: no-repeat;
    z-index: 99;
}

.projectVisualisationGateSelfSupporting .projectVisualisationBody__gateSelfSupporting {
    height: calc(100% - 214px);
    margin-top: 80px;
    left: 214px;
    width: calc(100% - 214px);
    position: relative;
}

.projectVisualisationGateSelfSupporting .projectVisualisationBody__gateSelfSupportingTop {
    height: 80px;
    width: calc(100% - 212px);
    left: 212px;
    top: 0;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateSelfSupporting/top.png");
}

.projectVisualisationGateSelfSupporting .projectVisualisationBody__gateSelfSupportingBottom {
    bottom: -102px;
    height: 262px;
    width: calc(100% - 212px);
    left: 212px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateSelfSupporting/bottom.png");
}

/*
* data-scale="2"
*/

.projectVisualisationGateSelfSupporting[data-scale="2"] .projectVisualisationGateSelfSupporting__span {
    height: calc(100% - 117px);
}

.projectVisualisationGateSelfSupporting[data-scale="2"] .projectVisualisationGateSelfSupporting__span {
    width: 106px;
    margin-top: 40px;
}

.projectVisualisationGateSelfSupporting[data-scale="2"] .projectVisualisationGateSelfSupporting__spanTop {
    height: 60px;
    top: -18px;
    width: 106px;
}

.projectVisualisationGateSelfSupporting[data-scale="2"] .projectVisualisationGateSelfSupporting__spanBottom {
    height: 129px;
    bottom: -49px;
    width: 154px;
}

.projectVisualisationGateSelfSupporting[data-scale="2"] .projectVisualisationBody__gateSelfSupporting {
    height: calc(100% - 106px);
    margin-top: 40px;
    left: 106px;
    width: calc(100% - 106px);
}

.projectVisualisationGateSelfSupporting[data-scale="2"] .projectVisualisationBody__gateSelfSupportingTop {
    height: 40px;
    left: 104px;
    width: calc(100% - 104px);
}

.projectVisualisationGateSelfSupporting[data-scale="2"] .projectVisualisationBody__gateSelfSupportingBottom {
    bottom: -49px;
    height: 129px;
    left: 104px;
    width: calc(100% - 104px);
}

/*
* data-scale="3"
*/

.projectVisualisationGateSelfSupporting[data-scale="3"] .projectVisualisationGateSelfSupporting__span {
    height: calc(100% - 78px);
}

.projectVisualisationGateSelfSupporting[data-scale="3"] .projectVisualisationGateSelfSupporting__span {
    width: 71px;
    margin-top: 26px;
}

.projectVisualisationGateSelfSupporting[data-scale="3"] .projectVisualisationGateSelfSupporting__spanTop {
    height: 40px;
    top: -12px;
    width: 106px;
}

.projectVisualisationGateSelfSupporting[data-scale="3"] .projectVisualisationGateSelfSupporting__spanBottom {
    height: 88px;
    bottom: -35px;
    width: 103px;
}

.projectVisualisationGateSelfSupporting[data-scale="3"] .projectVisualisationBody__gateSelfSupporting {
    height: calc(100% - 71px);
    margin-top: 26px;
    left: 70px;
    width: calc(100% - 70px);
}

.projectVisualisationGateSelfSupporting[data-scale="3"] .projectVisualisationBody__gateSelfSupportingTop {
    height: 26px;
    left: 68px;
    width: calc(100% - 68px);
}

.projectVisualisationGateSelfSupporting[data-scale="3"] .projectVisualisationBody__gateSelfSupportingBottom {
    bottom: -34px;
    height: 87px;
    left: 68px;
    width: calc(100% - 68px);
}

/*
* data-scale="4"
*/

.projectVisualisationGateSelfSupporting[data-scale="4"] .projectVisualisationGateSelfSupporting__span {
    height: calc(100% - 60px);
    width: 52px;
    margin-top: 19px;
}

.projectVisualisationGateSelfSupporting[data-scale="4"] .projectVisualisationGateSelfSupporting__spanTop {
    height: 29px;
    width: 52px;
    top: -9px;
}

.projectVisualisationGateSelfSupporting[data-scale="4"] .projectVisualisationGateSelfSupporting__spanBottom {
    height: 64px;
    bottom: -23px;
    width: 67px;
}

.projectVisualisationGateSelfSupporting[data-scale="4"] .projectVisualisationBody__gateSelfSupporting {
    height: calc(100% - 65px);
    margin-top: 20px;
    left: 51px;
    width: calc(100% - 24px);
}

.projectVisualisationGateSelfSupporting[data-scale="4"] .projectVisualisationBody__gateSelfSupportingTop {
    height: 20px;
    left: 51px;
    width: calc(100% - 24px);
}

.projectVisualisationGateSelfSupporting[data-scale="4"] .projectVisualisationBody__gateSelfSupportingBottom {
    bottom: -23px;
    height: 64px;
    left: 64px;
    width: calc(100% - 37px);
}
