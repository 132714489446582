.projectVisualisation {
    position: relative;
    background: #fff;
    margin-right: 3rem;
}

.projectVisualisationRod {
    display: flex;
    align-items: center;
    background: #545e64;
    color: #fff;
    padding-left: 15px;
}

.projectVisualisationSpace {
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.projectVisualisationAdditionalElementTop {
    padding-left: 15px;
}

.projectVisualisationSpace__small,
.projectVisualisationRod__small,
.projectVisualisationAdditionalElementTop__small {
  font-size: 10px;
}

.projectVisualisationSpace__contentHidden,
.projectVisualisationRod__contentHidden,
.projectVisualisationAdditionalElementTop__contentHidden {
    font-size: 0;
}
