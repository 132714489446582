.projectVisualisationGateway__spanLeft,
.projectVisualisationGateway__spanRight {
    height: calc(100% - 176px);
    top: 80px;
    width: 241px;
}

.projectVisualisationGateway__spanLeftBottom,
.projectVisualisationGateway__spanRightBottom,
.projectVisualisationGateway__spanLeftTop,
.projectVisualisationGateway__spanRightTop,
.projectVisualisationBody__gatewayTop,
.projectVisualisationBody__gatewayBottom,
.projectVisualisationGateway__spanLeft,
.projectVisualisationGateway__spanRight {
    position: absolute;
    background-size: contain;
}

.projectVisualisationGateway__spanLeft {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/left.png");
    left: 0;
}

.projectVisualisationGateway__spanLeftBottom,
.projectVisualisationGateway__spanRightBottom {
    width: 241px;
    height: 173px;
    background-repeat: no-repeat;
}

.projectVisualisationGateway__spanLeftTop,
.projectVisualisationGateway__spanRightTop {
    top: 0;
    width: 241px;
    height: 90px;
    background-repeat: no-repeat;
}

.projectVisualisationGateway__spanLeftBottom {
    left: 0;
    bottom: -72px;
}

.projectVisualisationGateway__spanRightBottom {
    right: 0;
    bottom: -68px;
}

.projectVisualisationGateway__spanLeftTop {
    left: 0;
}

.projectVisualisationGateway__spanLeftTop {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/top-left.png");
}

.projectVisualisationGateway__spanLeftBottom {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/bottom-left.png");
}

.projectVisualisationGateway__spanRight {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/right.png");
    right: 0;
}

.projectVisualisationGateway__spanRightTop {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/top-right.png");
    right: 0;
}

.projectVisualisationGateway__spanRightBottom {
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/bottom-right.png");
}

.projectVisualisationBody__gateway {
    height: calc(100% - 170px);
    margin-top: 80px;
    width: calc(100% - 482px);
    left: 241px;
    position: relative;
}

.projectVisualisationBody__gatewayTop,
.projectVisualisationBody__gatewayBottom {
    left: 241px;
    width: calc(100% - 480px);
}

.projectVisualisationBody__gatewayTop {
    top: 0;
    height: 80px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/top.png");
}

.projectVisualisationBody__gatewayBottom {
    bottom: -60px;
    height: 160px;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/gateway/bottom.png");
}

/*
* data-scale="2"
*/

.projectVisualisationGateway[data-scale="2"] .projectVisualisationBody__gateway {
    width: calc(100% - 240px);
    margin-top: 40px;
    left: 120px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationBody__gatewayTop {
    height: 40px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationBody__gatewayTop,
.projectVisualisationGateway[data-scale="2"] .projectVisualisationBody__gatewayBottom {
    width: calc(100% - 240px);
    left: 120px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanLeft,
.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanRight {
    height: calc(100% - 90px);
    top: 42px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanLeft {
    width: 120px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanRight {
    width: 120px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanLeftTop {
    width: 120px;
    height: 45px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanRightTop {
    width: 121px;
    height: 46px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanRightTop {
    /*right: 0;*/
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanLeftBottom {
    width: 120px;
    bottom: -30px;
    height: 81px;
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationGateway__spanRightBottom {
    width: 120px;
    bottom: -35px;
    height: 87px;
    /*right: 0;*/
}

.projectVisualisationGateway[data-scale="2"] .projectVisualisationBody__gatewayBottom {
    bottom: -30px;
    height: 80px;
}

/*
* data-scale="3"
*/

.projectVisualisationGateway[data-scale="3"] .projectVisualisationBody__gateway {
    width: calc(100% - 158px);
    margin-top: 30px;
    left: 80px;
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationBody__gatewayTop {
    height: 30px;
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationBody__gatewayTop,
.projectVisualisationGateway[data-scale="3"] .projectVisualisationBody__gatewayBottom {
    width: calc(100% - 158px);
    left: 80px;
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationGateway__spanLeft {
    width: 84px;
    top: 28px;
    height: calc(100% - 52px);
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationGateway__spanRight {
    width: 79px;
    top: 28px;
    height: calc(100% - 52px);
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationGateway__spanRightTop {
    width: 79px;
    height: 30px;
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationGateway__spanLeftTop {
    width: 84px;
    height: 30px;
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationGateway__spanLeftBottom {
    width: 84px;
    bottom: -33px;
    height: 57px;
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationGateway__spanRightBottom {
    width: 79px;
    height: 58px;
    bottom: -33px;
}

.projectVisualisationGateway[data-scale="3"] .projectVisualisationBody__gatewayBottom {
    bottom: -30px;
    height: 53px;
}

/*
* data-scale="4"
*/

.projectVisualisationGateway[data-scale="4"] .projectVisualisationBody__gateway {
    width: calc(100% - 120px);
    margin-top: 20px;
    left: 60px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationBody__gatewayTop {
    height: 20px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationBody__gatewayTop,
.projectVisualisationGateway[data-scale="4"] .projectVisualisationBody__gatewayBottom {
    width: calc(100% - 120px);
    left: 60px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanLeft,
.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanRight {
    height: calc(100% - 45px);
    top: 21px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanLeft {
    width: 60px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanRight {
    width: 60px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanLeftTop {
    width: 60px;
    height: 22px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanRightTop {
    width: 60px;
    height: 23px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanRightTop {
    /*right: 0;*/
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanLeftBottom {
    width: 60px;
    bottom: -16px;
    height: 42px;
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationGateway__spanRightBottom {
    width: 60px;
    bottom: -17px;
    height: 44px;
    /*right: 0;*/
}

.projectVisualisationGateway[data-scale="4"] .projectVisualisationBody__gatewayBottom {
    bottom: -14px;
    height: 40px;
}
