.projectVisualisationSpan {
    display: flex;
}

.projectVisualisationBody__span {
    width: calc(100% - 160px);
    position: relative;
}

.projectVisualisationSpan__spanLeft {
    width: 80px;
    height: 100%;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/span/left.png");
    background-size: contain;
}

.projectVisualisationSpan__spanRight {
    width: 80px;
    height: 100%;
    background-image: url("https://kns-diverso.s3.eu-central-1.amazonaws.com/visualisations/span/right.png");
    background-size: contain;
}

.projectVisualisationSpan[data-scale="2"] .projectVisualisationBody__span {
    width: calc(100% - 80px);
}

.projectVisualisationSpan[data-scale="2"] .projectVisualisationSpan__spanLeft {
    width: 40px;
}

.projectVisualisationSpan[data-scale="2"] .projectVisualisationSpan__spanRight {
    width: 40px;
}

.projectVisualisationSpan[data-scale="3"] .projectVisualisationBody__span {
    width: calc(100% - 53px);
}

.projectVisualisationSpan[data-scale="3"] .projectVisualisationSpan__spanLeft {
    width: 26px;
}

.projectVisualisationSpan[data-scale="3"] .projectVisualisationSpan__spanRight {
    width: 26px;
}

.projectVisualisationSpan[data-scale="4"] .projectVisualisationBody__span {
    width: calc(100% - 40px);
}

.projectVisualisationSpan[data-scale="4"] .projectVisualisationSpan__spanLeft {
    width: 20px;
}

.projectVisualisationSpan[data-scale="4"] .projectVisualisationSpan__spanRight {
    width: 20px;
}
