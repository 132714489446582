main {
    display: flex;
    flex-direction: column;
}

.btn-icon-left > svg {
    margin-right: 1rem;
}

.modal {
    background: rgba(0, 0, 0, .5);
}

.modal .close {
    font-size: 2.5rem;
}

.btn-danger {
    background: #ff0000;
    border-color: #ff0000;
}

.btn-danger:hover {
    background: #fff;
    border-color: #ff0000;
    color: #ff0000;
}

.btn-success:hover {
    background: transparent;
    color: #28a745;
}

.spinner {
    animation: fa-spin 2s linear infinite;
}

.btn-spinner {
    display: flex;
    align-items: center;
}

.btnWithTwoOptionsBtn:first-child {
    width: 60%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btnWithTwoOptionsBtn:last-child {
    width: 40%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}

.btnOptionsDescription {
    /* we have to do -.6rem because other class overwrite this */
    margin: .6rem 0 -.6rem 0;
}

.btnOptionsDescription .form-text {
    color: #fff;
}

.error {
    color: #ff0000;
    font-size: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
